<script lang="ts" setup>
import type { FooterSocialIcon } from './FooterSocialIcon.props'

import { iconsMap } from './FooterSocial.iconMapping'

const props = defineProps<FooterSocialIcon>()
</script>

<template>
  <li class="flex items-center">
    <MoleculesLinkWrapper
      :to="props.url"
      target="_blank"
      :aria-label="props.social"
      anatomy="link"
    >
      <template #iconLink>
        <component :is="iconsMap[props.social]" aria-hidden="true" />
      </template>
    </MoleculesLinkWrapper>
  </li>
</template>
